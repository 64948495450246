var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c(
            "section",
            { class: { "overflow-hidden": _vm.$vuetify.breakpoint.lgAndUp } },
            [
              _c("SiteTopBar", {
                attrs: {
                  selectedMapService: _vm.selectedMapService,
                  gisDataFieldsAndValues: _vm.gisDataFieldsAndValues,
                  attributes: _vm.attributes
                }
              }),
              _vm.loaded
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-16 px-3",
                      class: {
                        "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                        "py-0": _vm.$vuetify.breakpoint.mdAndDown
                      },
                      staticStyle: { height: "calc(100vh - 74px)" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "my-1 flex-grow-0" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("createNewForm")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mdiFileDocumentPlusOutline
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Create New Form")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection
                                                        .createNewForm
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.createNewForm,
                                      expression: "showSection.createNewForm"
                                    }
                                  ]
                                },
                                [
                                  _c("TicketFormDefDropdown", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "my-1 flex-grow-1" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick(
                                        "siteInformation"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiGoogleMaps) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Site Information")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection
                                                        .siteInformation
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.siteInformation,
                                      expression: "showSection.siteInformation"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-grow-1 overflow-y-auto"
                                    },
                                    [
                                      _c("GisInfo", {
                                        attrs: {
                                          globalId: _vm.gisInfoId,
                                          selectedMapServiceId:
                                            _vm.selectedMapServiceId,
                                          objectId: _vm.featureId,
                                          gisInfoAttributes:
                                            _vm.gisInfoAttributes
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "my-1 flex-grow-1" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("tasks")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mdiFormatListChecks
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Tasks")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.tasks
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.tasks,
                                      expression: "showSection.tasks"
                                    }
                                  ]
                                },
                                [
                                  _c("TasksTab", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "my-1" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("log")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiViewList) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Log")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.log
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.log,
                                      expression: "showSection.log"
                                    }
                                  ]
                                },
                                [
                                  _c("Log", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "my-1 flex-grow-1" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("documents")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " + _vm._s(_vm.mdiFile) + " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Documents")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.documents
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.documents,
                                      expression: "showSection.documents"
                                    }
                                  ]
                                },
                                [
                                  _c("DocsTab", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "flex-grow-1 d-flex justify-center align-center"
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: { indeterminate: "" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm.showActionItemDialog
                ? _c("ActionItemTabActionItemDialog", {
                    attrs: {
                      showActionItemDialog: _vm.showActionItemDialog,
                      selectedActionItem: _vm.selectedActionItem,
                      selectedActionItemId:
                        _vm.selectedActionItem.action_item_id,
                      action: _vm.getActionItemLabel(_vm.selectedActionItem)
                    },
                    on: {
                      "action-item-dialog-close": function($event) {
                        _vm.showActionItemDialog = false
                      },
                      "action-item-saved": function($event) {
                        _vm.showActionItemDialog = false
                      },
                      "action-item-response-saved": function($event) {
                        _vm.showActionItemDialog = false
                      },
                      "reject-action-item": function($event) {
                        _vm.showActionItemDialog = false
                      },
                      "accept-action-item": function($event) {
                        _vm.showActionItemDialog = false
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    persistent: "",
                    fullscreen: _vm.isFullScreen
                  },
                  model: {
                    value: _vm.showEditFormDialog,
                    callback: function($$v) {
                      _vm.showEditFormDialog = $$v
                    },
                    expression: "showEditFormDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _vm.showEditFormDialog
                        ? _c("DynamicForm", {
                            attrs: {
                              formDefinition:
                                _vm.dynamicFormProps.formDefinition,
                              existingFormResultIdMap:
                                _vm.dynamicFormProps.existingFormResultIdMap,
                              selectedPdfFileUrl:
                                _vm.dynamicFormProps.selectedPdfFileUrl,
                              canEdit: _vm.dynamicFormProps.canEdit,
                              alreadySubmittedFinalOnline:
                                _vm.dynamicFormProps
                                  .alreadySubmittedFinalOnline,
                              globalId: _vm.dynamicFormProps.globalId,
                              objectId: _vm.dynamicFormProps.objectId,
                              selectedMapServiceId:
                                _vm.dynamicFormProps.selectedMapServiceId
                            },
                            on: {
                              "ticket-edit-form-close-button-click": function(
                                $event
                              ) {
                                _vm.showEditFormDialog = false
                              },
                              "ticket-edit-form-close": function($event) {
                                _vm.showEditFormDialog = false
                              },
                              "ticket-edit-form-submitted": function($event) {
                                _vm.showEditFormDialog = false
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
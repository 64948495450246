import { db } from "@/mixins/utilisync-db";
import axios from "axios";

const cancelTokenSource = axios.CancelToken.source();

export default {
  methods: {
    async signOut() {
      if (!navigator.onLine) {
        return;
      }
      cancelTokenSource.cancel();
      const layerVisibilitySettings = localStorage.getItem(
        "layer-visibility-settings"
      );
      await db.delete();
      localStorage.clear();
      localStorage.setItem(
        "layer-visibility-settings",
        layerVisibilitySettings
      );
      this.$router.push({ name: "Login", query: undefined }, () => {
        this.$router.go(0);
      });
    },
  },
};

<template>
  <v-app style="background-color: #fafafa">
    <v-main fluid tag="section" class="pt-0">
      <section :class="{ 'overflow-hidden': $vuetify.breakpoint.lgAndUp }">
        <SiteTopBar
          :selectedMapService="selectedMapService"
          :gisDataFieldsAndValues="gisDataFieldsAndValues"
          :attributes="attributes"
        />

        <v-row
          class="mt-16 px-3"
          v-if="loaded"
          :class="{
            'my-0': $vuetify.breakpoint.mdAndDown,
            'py-0': $vuetify.breakpoint.mdAndDown,
          }"
          style="height: calc(100vh - 74px)"
        >
          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-0">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('createNewForm')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFileDocumentPlusOutline }}
                    </v-icon>
                  </v-btn>
                  <div>Create New Form</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.createNewForm
                          ? mdiChevronUp
                          : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.createNewForm">
                <TicketFormDefDropdown
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('siteInformation')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiGoogleMaps }}
                    </v-icon>
                  </v-btn>
                  <div>Site Information</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.siteInformation
                          ? mdiChevronUp
                          : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.siteInformation">
                <div class="flex-grow-1 overflow-y-auto">
                  <GisInfo
                    :globalId="gisInfoId"
                    :selectedMapServiceId="selectedMapServiceId"
                    :objectId="featureId"
                    :gisInfoAttributes="gisInfoAttributes"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('tasks')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFormatListChecks }}
                    </v-icon>
                  </v-btn>
                  <div>Tasks</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{ showSection.tasks ? mdiChevronUp : mdiChevronDown }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.tasks">
                <TasksTab
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('log')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiViewList }}
                    </v-icon>
                  </v-btn>
                  <div>Log</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{ showSection.log ? mdiChevronUp : mdiChevronDown }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.log">
                <Log
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('documents')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFile }}
                    </v-icon>
                  </v-btn>
                  <div>Documents</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.documents ? mdiChevronUp : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.documents">
                <DocsTab
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card class="d-flex flex-column">
              <v-card-text
                class="flex-grow-1 d-flex justify-center align-center"
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <ActionItemTabActionItemDialog
          v-if="showActionItemDialog"
          :showActionItemDialog="showActionItemDialog"
          :selectedActionItem="selectedActionItem"
          :selectedActionItemId="selectedActionItem.action_item_id"
          :action="getActionItemLabel(selectedActionItem)"
          @action-item-dialog-close="showActionItemDialog = false"
          @action-item-saved="showActionItemDialog = false"
          @action-item-response-saved="showActionItemDialog = false"
          @reject-action-item="showActionItemDialog = false"
          @accept-action-item="showActionItemDialog = false"
        />

        <v-dialog
          v-model="showEditFormDialog"
          max-width="600px"
          persistent
          :fullscreen="isFullScreen"
        >
          <v-card>
            <DynamicForm
              :formDefinition="dynamicFormProps.formDefinition"
              :existingFormResultIdMap="
                dynamicFormProps.existingFormResultIdMap
              "
              :selectedPdfFileUrl="dynamicFormProps.selectedPdfFileUrl"
              :canEdit="dynamicFormProps.canEdit"
              :alreadySubmittedFinalOnline="
                dynamicFormProps.alreadySubmittedFinalOnline
              "
              :globalId="dynamicFormProps.globalId"
              :objectId="dynamicFormProps.objectId"
              :selectedMapServiceId="dynamicFormProps.selectedMapServiceId"
              @ticket-edit-form-close-button-click="showEditFormDialog = false"
              @ticket-edit-form-close="showEditFormDialog = false"
              @ticket-edit-form-submitted="showEditFormDialog = false"
              v-if="showEditFormDialog"
            />
          </v-card>
        </v-dialog>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import { mdiArrowCollapse } from "@mdi/js";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import Log from "@/components/tickets/Log.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import GisInfo from "@/components/mapView/GisInfo";
import {
  mdiGoogleMaps,
  mdiCheckboxMarkedOutline,
  mdiFile,
  mdiFormatListChecks,
  mdiMenu,
  mdiFileDocumentPlusOutline,
  mdiViewList,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import { db } from "@/mixins/utilisync-db";
import { mapMutations, mapGetters } from "vuex";
import SiteTopBar from "@/components/site/SiteTopBar";
import { axiosWithNoAuth } from "@/plugins/axios";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import signOutMixin from "@/mixins/signOutMixin";
import usetifulMixin from "@/mixins/usetifulMixin";
import moment from "moment";
import ActionItemTabActionItemDialog from "@/components/mapView/action-items-tab/ActionItemTabActionItemDialog";
import actionItemMixin from "@/mixins/actionItemMixin";
import { axiosWithRegularAuth } from "@/plugins/axios";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getFormResultMixin from "@/mixins/getFormResultMixin";
import { v4 as uuidv4 } from "uuid";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Site",
  components: {
    TicketFormDefDropdown,
    DocsTab,
    TasksTab,
    Log,
    GisInfo,
    SiteTopBar,
    ActionItemTabActionItemDialog,
    DynamicForm,
  },
  mixins: [
    gisInfoMixin,
    downloadDataMixin,
    bulkDownloadDataMixin,
    signOutMixin,
    usetifulMixin,
    actionItemMixin,
    fullScreenCheckMixin,
    getFormResultMixin,
  ],
  computed: {
    ...mapGetters(["isMenuOpen", "numUnsubmittedTickets", "dynamicFormProps"]),
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (!this.attributes || !Array.isArray(this.fields)) {
          return {};
        }

        const entries = this.fields.map(({ name: key }) => {
          const value = this.attributes[key];

          return [this.findAliasByName(this.fields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        const entries = this.gisDataFieldsAndValues.map((fv) => {
          const { name, value } = fv;
          return [name, value];
        });
        return Object.fromEntries(entries);
      }
    },
    notUtiliSyncLayer() {
      return this.selectedMapService?.service_type !== "U";
    },
    title() {
      return this.selectedMapService.service_name;
    },
    subtitle() {
      if (this.notUtiliSyncLayer) {
        return this.attributes[this.selectedMapService.ref_field];
      }
      const { ref_field: refField } = this.selectedMapService;
      return (
        this.gisDataFieldsAndValues.find((f) => f.name === refField)?.value ??
        "[Not Provided]"
      );
    },
  },
  data() {
    return {
      mdiArrowCollapse,
      mdiGoogleMaps,
      mdiCheckboxMarkedOutline,
      mdiFile,
      mdiFormatListChecks,
      mdiMenu,
      mdiFileDocumentPlusOutline,
      mdiViewList,
      mdiChevronUp,
      mdiChevronDown,
      gisInfoId: undefined,
      featureId: undefined,
      selectedMapServiceId: undefined,
      attributes: {},
      fields: [],
      selectedMapService: {},
      gisDataFieldsAndValues: [],
      loaded: false,
      selectedActionItem: {},
      showActionItemDialog: false,
      showEditFormDialog: false,
      showSection: {
        createNewForm: true,
        siteInformation: true,
        tasks: true,
        log: true,
        documents: true,
      },
    };
  },
  methods: {
    ...mapMutations(["setMenuState", "setDynamicFormProps"]),
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async getFeature() {
      const {
        layer: mapServiceId,
        feature_id: objectId,
        global_id: globalId,
      } = this.$route.query;
      this.selectedMapServiceId = mapServiceId;

      const [results] = await db.mapServices
        .filter((m) => m.map_service_id === mapServiceId)
        .toArray();
      this.selectedMapService = results;
      if (this.selectedMapService.service_type === "F") {
        this.gisInfoId = globalId;
        this.featureId = +objectId;
        if (navigator.onLine) {
          const mapServiceUrl = results.service_url;
          let queryResult = {};
          try {
            const { data } = await axiosWithNoAuth.get(
              `${mapServiceUrl}/query`,
              {
                params: {
                  objectids: objectId,
                  outFields: "*",
                  f: "json",
                  token: localStorage.getItem("esri_token"),
                },
              }
            );
            queryResult = data;
            const { features, fields } = queryResult;
            const [{ attributes }] = features ?? [{}];
            this.attributes = attributes ?? {};
            this.fields = fields;
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        this.featureId = +objectId;
        const gisDataValues = await db.gisDataValues.toCollection().toArray();
        const gisDataFields = await db.gisDataFields
          .filter((g) => {
            return g.map_service_id === mapServiceId;
          })
          .toArray();

        this.gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
          const { name } = gdf;
          const value = gisDataValues?.find((gdv) => {
            return (
              gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
              +gdv?.feature_id === +objectId
            );
          })?.value;

          return {
            name,
            value,
          };
        });
        this.gisInfoId = globalId;
      }
    },
    async openFormForTask() {
      const { task_id: taskId } = this.$route.query;
      if (!taskId) {
        return;
      }
      const {
        data: { results: task },
      } = await axiosWithRegularAuth.get(`${APIURL}/tasks/${taskId}`);
      const {
        form_definition_id: formDefinitionId,
        global_id: globalId,
        feature_id: objectId,
        map_service_id: selectedMapServiceId,
      } = task;
      const [formDefinition] = await db.formDefinitions
        .filter((f) => f.form_definition_id === formDefinitionId)
        .toArray();
      const dynamicFormProps = {
        formDefinition,
        existingFormResultIdMap: {
          formResultId: uuidv4(),
        },
        canEdit: true,
        globalId,
        objectId,
        selectedMapServiceId,
        taskId,
      };
      this.setDynamicFormProps(dynamicFormProps);
    },
    async openActionItemDialogOnPageLoad() {
      const { action_item_id: actionItemId } = this.$route.query;
      if (!actionItemId) {
        return;
      }

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/action_item/${actionItemId}`
      );
      if (!results) {
        return;
      }
      this.selectedActionItem = results;
      this.showActionItemDialog = true;
    },
    onHeadingClick(sectionName) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$set(
          this.showSection,
          sectionName,
          !this.showSection[sectionName]
        );
      }
    },
  },
  async beforeMount() {
    this.getFeature();
    this.openFormForTask();
    try {
      if (localStorage.getItem("bulk-download-complete") !== "true") {
        await this.bulkDownloadData();
      }
      localStorage.setItem("bulk-download-complete", true);
      const lastFullDownloadCompleted = localStorage.getItem(
        "last-full-download-completed"
      );
      const fullDownload =
        !lastFullDownloadCompleted ||
        (lastFullDownloadCompleted &&
          moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
      await this.downloadData(!fullDownload);
    } finally {
      this.loaded = true;
    }
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  async mounted() {
    const formResultId = sessionStorage.getItem("formResultId");
    if (formResultId) {
      await this.getFormResult(formResultId);
    }
  },
  watch: {
    dynamicFormProps: {
      deep: true,
      async handler(val) {
        this.showEditFormDialog = false;
        await this.$nextTick();
        if (
          val?.formDefinition?.form &&
          val?.globalId &&
          val?.objectId &&
          val?.selectedMapServiceId
        ) {
          this.showEditFormDialog = true;
        }
      },
    },
    "$route.query.action_item_id": {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        await this.openActionItemDialogOnPageLoad();
      },
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}

.disable-pointer {
  pointer-events: none;
}
</style>

import { axiosWithRegularAuth } from "@/plugins/axios";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  methods: {
    ...mapMutations(["setDynamicFormProps"]),
    async getFormResult(formResultId) {
      if (formResultId) {
        try {
          sessionStorage.setItem("formResultId", formResultId);
          const { data: selectedFormResult } = await axiosWithRegularAuth.get(
            `${APIURL}/form_results/${formResultId}`
          );
          const existingFormResultIdMap = {
            formResultId,
          };
          const selectedPdfFileUrl = selectedFormResult.pdfFileUrl;
          const { canEditAfterFinal } =
            selectedFormResult?.form?.formDescription ?? {};
          const alreadySubmittedFinalOnline =
            selectedFormResult?.form?.status === "SUBMITTED_FINAL";
          const { feature_id: objectId, map_service_id: mapServiceId } =
            selectedFormResult;
          const { gisDataPointId } = selectedFormResult.feature_attributes;
          const dynamicFormProps = {
            selectedFormResult,
            existingFormResultIdMap,
            alreadySubmittedFinalOnline,
            formDefinition: selectedFormResult,
            canEdit:
              (alreadySubmittedFinalOnline && canEditAfterFinal) ||
              !alreadySubmittedFinalOnline,
            selectedPdfFileUrl,
            objectId,
            globalId: gisDataPointId,
            selectedMapServiceId: mapServiceId,
          };
          this.setDynamicFormProps(dynamicFormProps);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  async mounted() {
    const formResultId = sessionStorage.getItem("formResultId");
    if (formResultId) {
      await this.getFormResult(formResultId);
    }
  },
};

<template>
  <v-app-bar color="#3F51B5" class="white--text py-0 my-0" height="56px" app>
    <v-app-bar-nav-icon>
      <v-btn text color="white" @click="toggleMenu(!isMenuOpen)">
        <v-badge
          color="orange"
          :content="numUnsubmittedTickets"
          overlap
          :value="numUnsubmittedTickets > 0"
        >
          <v-icon>{{ mdiMenu }}</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar-nav-icon>

    <div
      class="d-flex justify-space-between align-center"
      style="font-size: 12px; width: 100%"
    >
      <div class="white--text">
        <div>
          {{ title }}
        </div>

        <div class="white--text">
          {{ subtitle }}
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mdiMenu } from "@mdi/js";

export default {
  name: "SiteTopBar",
  computed: {
    ...mapGetters(["isMenuOpen", "numUnsubmittedTickets"]),
    notUtiliSyncLayer() {
      return this.selectedMapService?.service_type !== "U";
    },
    title() {
      return this.selectedMapService?.service_name;
    },
    subtitle() {
      if (this.notUtiliSyncLayer) {
        return this.attributes[this.selectedMapService?.ref_field];
      }
      const { ref_field: refField } = this.selectedMapService;
      return (
        this.gisDataFieldsAndValues?.find((f) => f.name === refField)?.value ??
        "[Not Provided]"
      );
    },
  },
  data() {
    return {
      mdiMenu,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
  },
  props: {
    selectedGisInfo: Object,
    selectedMapService: Object,
    gisDataFieldsAndValues: Array,
    attributes: Object,
  },
};
</script>
